<template>
  <div>
    <Toolbar :handle-delete="del">
      <template slot="left">
        <v-toolbar-title v-if="item">{{ `${$options.servicePrefix} ${item['@id']}` }}</v-toolbar-title>
      </template>
    </Toolbar>
    <div v-if="item">
      <v-card max-width="860px" class="mt-4 mx-auto">
        <v-card flat class="mt-5">
          <v-card-text class="text-center">
            <h4 class="mb-1 grey--text">{{ item.email }}</h4>
            <h1 class="font-weight-light mb-3 black--text">
              {{ profile.salutation }} {{ profile.title }} {{ profile.firstName }}
              {{ profile.lastName }}
            </h1>
            <h5 class="mb-1 grey--text" v-if="profile.company">{{ profile.company }}</h5>
            <v-row>
              <v-col cols="12" sm="4" offset-sm="2">
                <h3>Bestätigt</h3>
                <v-icon v-if="item.confirmed == null">mdi-check-circle</v-icon>
                <v-icon v-else>mdi-close-circle</v-icon>
              </v-col>
              <v-col cols="12" sm="4">
                <h3>Aktiv</h3>
                <v-icon v-if="item.enabled">mdi-check-circle</v-icon>
                <v-icon v-else>mdi-close-circle</v-icon>
              </v-col>
              <v-col cols="12" sm="6">
                <h3>Erstellt</h3>
                am <strong>{{ formatDateTime(item.createdAt, 'L') }}</strong><br/>
                von <strong>{{ item.createdBy }}</strong><br/>
              </v-col>
              <v-col cols="12" sm="6" col>
                <h3>Aktualisiert</h3>
                am <strong>{{ formatDateTime(item.updatedAt, 'L') }}</strong><br/>
                von <strong>{{ item.updatedBy }}</strong><br/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card flat :loading="!tenantUsersLoaded" class="mt-5">
          <v-card-title>{{ $t('TenantUsersAccess') }}</v-card-title>
          <v-card-text>
            <template v-if="tenantUsersLoaded">
              <v-data-table
                :headers="tenantUserHeaders"
                :items="tenantUserObjects"
                :loading="loadingFlags.tenant_users"
                :loading-text="$t('Loading...')"
                class="elevation-1"
                item-key="@id"
                v-model="tenantUsersSelected"
              >
                <template slot="item.tenant" slot-scope="{ item }">
                  {{ getTenantItem(item['tenant'], 'name') }}
                </template>
                <template slot="item.groups" slot-scope="{ item }">
                  <v-chip v-for="group in item.groups" :key="group">
                    {{ getTenantGroupItem(group, 'name') }}
                  </v-chip>
                </template>
                <ActionCell
                  :handle-delete="() => deleteTenantUserHandler(props.item)"
                  :handle-edit="() => createOrEditTenantUserHandler(props.item)"
                  slot="item.action"
                  slot-scope="props"
                ></ActionCell>
              </v-data-table>
            </template>
            <template v-else>
              <p>Keine Daten verfügbar</p>
            </template>
            <v-btn color="primary" dark @click="createOrEditTenantUserHandler(null)">
              {{ $t('AddTenantUser') }}
            </v-btn>
            <TenantUserDialog
              v-model="showTenantUserDialogForm"
              :tenant-user-item="tenantUserFormItem"
              :handle-created="onTenantUserCreatedOrUpdated"
              :handle-updated="onTenantUserCreatedOrUpdated"
              :show-handle="showTenantUserDialogForm"
              :available-tenants="tenantsForForm"
              :group-select="true"
              :title="$t('TenantUsersAccess')"
              @close="tenantUserDialogControl(true)"
            />

          </v-card-text>
        </v-card>
      </v-card>
    </div>
    <Loading :visible="isLoading"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import Loading from '../../components/Loading';
import ShowMixin from '../../mixins/ShowMixin';
import Toolbar from '../../components/Toolbar';
import TenantUserDialog from '../../components/tenantUser/Dialog';
import ActionCell from "@/components/ActionCell";
import TenantUserMixin from "@/mixins/TenantUserMixin";
import TenantMixin from "@/mixins/TenantMixin";
import ProfileMixin from "@/mixins/ProfileMixin";
import TenantGroupMixin from "@/mixins/TenantGroupMixin";

const servicePrefix = 'BackendUser';

export default {
  name: 'BackendUserShow',
  servicePrefix,
  components: {
    Loading,
    Toolbar,
    TenantUserDialog,
    ActionCell
  },
  mixins: [ShowMixin, TenantUserMixin, TenantMixin, ProfileMixin, TenantGroupMixin],
  mounted() {
    this.tenantGroupsGetSelectItems();
    this.tenantsGetSelectItems();
  },
  data() {
    return {
      tab: true,
      loadedTenantUserItems: 0,
      tenantsForForm: [],
      tenantUsersSelected: [],
      tenantUserHeaders: [
        {text: this.$i18n.t('TenantUserName'), value: 'tenant'},
        {text: this.$i18n.t('TenantUserGroups'), value: 'groups'},
        {
          text: this.$i18n.t('Actions'),
          value: 'action',
          sortable: false
        }
      ],
      showTenantUserDialogForm: false,
      tenantUserFormItem: {},
      loadingFlags: {
        'tenant_users': false
      },
    };
  },
  computed: {
    ...mapFields('backendUser', {
      isLoading: 'isLoading',
      relatedTenantUsers: 'related.tenant_users.byId',
      relatedTenantUsersCount: 'related.tenant_users.totalItems',
    }),
    ...mapFields('tenant', {
      tenantSelectItems: 'selectItems',
    }),
    ...mapGetters('backendUser', {find: 'find'}),
    tenantUsersLoaded() {
      return this.item.tenantUsers.length === this.tenantUserObjects.length;
    },
    tenantUserObjects() {
      return this.item.tenantUsers.map((tenantUser) => this.resolveTenantUser(tenantUser)).filter(e => e);
    },
    availableTenants() {
      return this.tenantSelectItems.filter((tenant) => this.tenantUserObjects.findIndex(p => p.tenant === tenant['@id']) === -1);
    },
    profile() {
      if (this.profileCreate && (typeof this.item.profile === 'object' && this.item.profile !== null)) {
        // create object, keep it simple
        return this.item.profile;
      }
      if (typeof this.item.profile !== "undefined") {
        return this.resolveProfile(this.item.profile);
      }
      return null;
    }
  },
  methods: {
    log(message) {
      console.log(message)
    },
    ...mapActions('backendUser', {
      deleteItem: 'del',
      reset: 'reset',
      retrieve: 'load',
      updateBackendUser: 'update',
    }),
    ...mapActions('tenantGroup', {
      tenantGroupsGetSelectItems: 'fetchSelectItems'
    }),
    onTenantUserCreatedOrUpdated(tenantUser) {
      this.$store.dispatch('addRelation', {
        iri: this.item['@id'],
        relationName: 'tenantUsers',
        toIri: tenantUser['@id']
      });
    },
    tenantUserDialogControl(close = false) {
      this.showTenantUserDialogForm = !close;
    },
    createOrEditTenantUserHandler(tenantUserItem) {
      this.tenantUserFormItem = tenantUserItem ?? {backendUser: this.item['@id'], groups: []};
      this.tenantsForForm = this.availableTenants;
      if (tenantUserItem !== null) {
        this.tenantsForForm.push(tenantUserItem.tenant);
      }
      this.tenantUserDialogControl();
    },
    deleteTenantUserHandler(tenantUserToDelete) {
      this.deleteTenantUserItem(tenantUserToDelete);
      this.$store.dispatch('removeRelation', {
        iri: this.item['@id'],
        relationName: 'tenantUsers',
        toIri: tenantUserToDelete['@id']
      });
    },
  },
  watch: {
    deletedTenantUserItem() {
      this.showMessage(`TenantUser deleted.`);
    }
  }
};
</script>
