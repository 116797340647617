import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
  data() {
    return {
      loadingTenantGroups: [],
    };
  },
  computed: {
    ...mapFields("tenantGroup", {
      tenantGroupIds: "allIds",
      tenantGroupIdsCached: "cacheAllIds",
    }),
    ...mapGetters("tenantGroup", {
      findTenantGroup: "find",
    }),
  },
  methods: {
    ...mapActions("tenantGroup", {retrieveTenantGroup: "load"}),
    resolveTenantGroup(itemId) {
      if (!this.isTenantGroupLoaded(itemId) && !this.loadingTenantGroups.includes(itemId)) {
        this.loadingTenantGroups.push(itemId);
        this.retrieveTenantGroup(decodeURIComponent(itemId));
      }
      return this.findTenantGroup(itemId);
    },
    resolveTenantGroups(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolveTenantGroup(itemId);
      }).filter(e => e);
    },
    getTenantGroupItem(tenantGroupIri, type) {
      if (tenantGroupIri !== null) {
        let tenantGroup = this.resolveTenantGroup(tenantGroupIri);
        if (tenantGroup) {
          return tenantGroup[type];
        }
      }
      return '';
    },
    isTenantGroupLoaded(itemId) {
      return this.tenantGroupIdsCached.includes(itemId);
    },
    isTenantGroupSetLoaded(itemIds) {
      return itemIds.every(itemId => this.isTenantGroupLoaded(itemId));
    },
    resolveTenantGroupAsync(itemId) {
      const poll = resolve => {
        if (this.isTenantGroupLoaded((itemId))) {
          resolve(this.resolveTenantGroup(itemId));
        } else {
          this.resolveTenantGroup(itemId);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    },
    resolveTenantGroupsAsync(itemIds) {
      const poll = resolve => {
        if (this.isTenantGroupSetLoaded((itemIds))) {
          resolve(this.resolveTenantGroups(itemIds));
        } else {
          this.resolveTenantGroup(itemIds);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    },
  }
};
